
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { setTitle, handle429 } from '@util/util.ts';
import { app } from '@config';
import axios from 'axios';
import PageMain from '@part/layout/PageMain.vue';
import SliderBlock from '@part/blocks/SliderBlock.vue';
import HighlightedProgramsBlock from '@part/blocks/HighlightedProgramsBlock.vue';
import AboutBlock from '@part/blocks/AboutBlock.vue';
import HighlightedAmbassadorsBlock from '@part/blocks/HighlightedAmbassadorsBlock.vue';
import TicketsBlock from '@part/blocks/TicketsBlock.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        PageMain,
        SliderBlock,
        HighlightedProgramsBlock,
        AboutBlock,
        HighlightedAmbassadorsBlock,
        TicketsBlock
    },
    props: [],
    data() {
        return {
            pageData: {},
            SliderBlock: SliderBlock,
            HighlightedProgramsBlock: HighlightedProgramsBlock,
            AboutBlock: AboutBlock,
            HighlightedAmbassadorsBlock: HighlightedAmbassadorsBlock,
            TicketsBlock: TicketsBlock
        };
    },
    mounted() {
        const instance = axios.create({
            baseURL: app.api.api,
            timeout: 2500,
            headers: {}
        });

        instance.get(`/page/${this.$route.meta.id}`).then(response => {
            this.pageData = response.data;
            setTitle(response);
            return response.data;
        })
        .catch(error => {
            console.log(`🔥${error}🔥`);
            handle429(this, error);
        });
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Home extends Vue {}
